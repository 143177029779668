#App
{
	display: flex;
	flex-direction: column;
	position: relative;
}

.builderAlert
{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 300;
}
.alertContainer
{
	z-index: 301;
	width: auto;
	max-width: 350px;
	padding: 32px;
	margin-top: 128px;
	border-radius: 32px;
	box-shadow: 4px 4px 8px 4px rgb(0 0 0 / 25%);
	background-color: var(--secondary);
}
.alertMessage
{
	display: block;
	padding: 16px;
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
}
.alertButton
{
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	color: var(--secondary);
	border: none;
	border-radius: 4px;
	padding: 8px;
	cursor: pointer;
	margin-left: 32px;
}
.alertOKEY
{
	background-color: var(--midle);
}
.alertNO
{
	background-color: var(--danger);
}
.b_dropdown
{
	position: relative;
}
.b_selectedValue
{
	display: flex;
	flex-direction: row;
	cursor: pointer;
}
.b_dropIcon
{
	border: none;
	background-color: transparent;
	font-size: 16px;
	margin-left: 8px;
}
.b_listItems
{
	position: absolute;
	display: none
}