/*Phones*/
@media screen and (max-width: 600px) {
    body
    {
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

    #App
    {
        display: flex;
        flex-direction: column;
        width: 92vw;
        height: 100vh;
        position: relative;
    }

    .vicles_land
    {
        display: flex;
        flex-direction: column;
    }

    .vicles_header
    {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;
    }

    .vicles_trapez
    {
        position: absolute;
        top: 0;
        right: -4vw;
        grid-area: 1 / 3 / 5 / 6;
        height: 100vh;
        aspect-ratio: 9 / 6;
    }

    .vicles_circles
    {
        display: none;
    }

    .vicles_circle_container
    {
        display: none;
    }

    .vicles_trapcirc
    {
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 0px 0px;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        top: 0;
        right: 0;
    }

    .vicles_notif
    {
        display: none;
    }

    .vicles_square
    {
        display: none;
    }

    .vicles_triangle
    {
        display: none;
    }

    .vicles_cursor
    {
        display: none;
    }

    .vicles_navbar {
        position: relative;
        width: 100%;
        height: 72px;
        display: grid;
        align-content: center;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .vicles_logo
    {
        font-family: 'righteous', cursive;
        font-size: 36px;
        color: var(--white);
        grid-area: 1 / 1 / 2 / 2;
        display: flex;
        align-items: center;
    }

    .vicles_drop_langs .b_listItems
    {
        background-color: var(--white);
        padding: 8px;
        border-radius: 8px;
    }

    .vicles_drop_langs .b_listItems span
    {
        display: block;
        color: var(--black);
        cursor: pointer;
    }

    .vicles_drop_langs .b_dropIcon
    {
        color: var(--white);
        font-size: 16px;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
    }

    .vicles_languages
    {
        grid-area: 1 / 8 / 2 / 9;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        font-size: 16px !important;
        font-family: 'poppins', cursive;
        color: var(--white60);
    }

    .ri-earth-line{
        font-size: 24px !important;
    }
    .vicles_globe
    {
        background-color: transparent;
        border: none;
        color: var(--white60);
    }

    .vicles_hero {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
        "HookMessage"
        "HookMessage"
        "HookMessage";
        height: calc(100vh - 72px);
    }
    .vicles_position_block_hook {
        grid-area: HookMessage;
        display: grid;
        grid-template-columns: 1.7fr;
        grid-template-rows: 1fr 1.5fr 0.5fr;
        gap: 12px 0px;
        grid-template-areas:
            "."
            "."
            ".";
    }
    .vicles_hook {
        ont-family: "poppins", cursive;
        font-weight: bold;
        font-size: 32px;
        color: var(--white);
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .vicles_hookMessage {
        font-family: "poppins", cursive;
        font-size: 18px;
        color: var(--white);
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .vicles_join_us
    {
        border-radius: 12px;
        border: none;
        outline: 2px solid var(--black15);
        font-family: 'poppins', cursive;
        font-weight: bold;
        color: var(--white);
        background-color: var(--main);
        cursor: pointer;
        font-size: 24px;
        width: fit-content;
        height: fit-content;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .vicles_join_us:hover
    {
        background-color: var(--white);
        color: var(--main);
    }

    .vicles_benefits_dock {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 12px 12px;
        grid-template-areas: ". . . .";
        overflow-x: scroll;
    }

    .vicles_benefits_card {
        width: 50vw;
        background-color: var(--white);
        border: 2px solid var(--black15);
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: center;
        padding: 24px 8px 24px 8px;
        border-radius: 16px;
        box-shadow: none;
    }

    .vicles_hero_bottom {
        width: 80vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0.2fr 0.4fr;
        gap: 0px 0px;
        grid-template-areas:
        "."
        "."
        ".";
        padding: 0;
    }

    .vicles_hero_bottom_revert {
        width: 80vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0.2fr 0.4fr;
        gap: 0px 0px;
        grid-template-areas:
        "."
        "."
        ".";
        padding: 0;
    }

    .vicles_hero_bottom_image {
        grid-area: unset;
        width: 80vw;
        aspect-ratio: initial;

    }

    .vicles_hero_bottom_image_revert {
        grid-area: unset;
        width: 80vw;
        aspect-ratio: initial;
    }

    .vicles_hero_bottom_title {
        grid-area: unset;
        font-family: "poppins", cursive;
        font-weight: bold;
        font-size: 20px;
    }

    .vicles_hero_bottom_text {
        grid-area: unset;
        font-family: "poppins", cursive;
        font-size: 16px;
        display: flex;
        align-self: center;
    }

    .vicles_foooter_cta_join {
        background-color: var(--white);
        color: var(--main);
        border: none;
        outline: 2px solid var(--white60);
        font-family: "poppins", cursive;
        font-weight: bold;
        padding: 16px;
        cursor: pointer;
        margin: 24px 0 0 0;
        font-size: 30px;
        border-radius: 16px;
        align-self: start;
    }

    .vicles_footer_cta_aligner {
        width: 92vw;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .vicles_register_window {
        width: 80vw;
        padding: 16px;
        border-radius: 16px;
        position: relative;
        z-index: 31;
        background-color: var(--white);
    }

    .vicles_register_field {
        border: none;
        background-color: var(--offWhite);
        font-family: "poppins", cursive;
        font-size: 20px;
        padding: 8px;
        border-radius: 8px;
        width: 80%;
        display: flex;
        justify-self: center;
    }
}