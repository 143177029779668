/*Tablets*/
@media screen and (max-width: 1024px) {
    .vicles_header
    {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;
    }

    .vicles_trapez
    {
        position: absolute;
        top: 0;
        right: -4px;
        grid-area: 1 / 3 / 5 / 6;
        height: 80vh;
        aspect-ratio: 4 / 5;
    }

    .vicles_circle_container {
        position: absolute;
        grid-area: 1 / 3 / 5 / 6;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
        ". . . . ."
        ". circle circle circle ."
        ". circle circle circle ."
        ". circle circle circle ."
        ". . . . .";
        top: 0;
        right: -4px;
    }

    .vicles_circles
    {
        width: 500px;
        grid-area: circle;
    }

    .vicles_trapcirc
    {
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        gap: 0px 0px;
        z-index: -1;
        width: calc(100vw - 64px);
        height: 60vh;
        top: 0;
        right: -64px;
    }

    .vicles_notif
    {
        height: 200px;
        aspect-ratio: initial;
        position: absolute;
        grid-area: 2 / 2 / 3 / 4;
    }

    .vicles_square
    {
        position: absolute;
        left: 8vw;
        top: 13vh;
        z-index: -1;
    }

    .vicles_triangle
    {
        position: absolute;
        left: 18vw;
        top: 23vh;
        z-index: -1;
    }

    .vicles_cursor
    {
        position: absolute;
        left: calc(-4vw - 16px);
        top: 22vh;
        z-index: -1;
    }


    .vicles_navbar
    {
        width: 100%;
        height: 128px;
        display: grid;
        align-content: center;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .vicles_logo
    {
        font-family: 'righteous', cursive;
        font-size: 56px;
        color: var(--main);
        grid-area: 1 / 1 / 2 / 2;
        display: flex;
        align-items: center;
    }

    .vicles_languages
    {
        grid-area: 1 / 8 / 2 / 9;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        font-size: 36px;
        color: var(--white60);
        font-family: 'poppins', cursive;
    }

    .ri-earth-line{
        font-size: 36px !important;
    }
    .vicles_globe
    {
        background-color: transparent;
        border: none;
        color: var(--white60);
    }

    .vicles_hero
    {
        height: 70vh;
        position: relative;
        left: 0;
        top: 0;
        display: grid;
        grid-template-columns: 1fr 0.3fr 1.7fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
        ". . ."
        "HookMessage HookMessage ."
        "HookMessage HookMessage .";
        z-index: 10;
    }

    .vicles_position_block_hook
    {
        grid-area: HookMessage;
        display: grid;
        grid-template-columns: 1.7fr;
        grid-template-rows: 1fr 1.7fr 0.3fr;
        gap: 12px 0px;
        grid-template-areas:
        "."
        "."
        ".";
    }

    .vicles_hook
    {
        font-family: 'poppins', cursive;
        font-weight: bold;
        font-size: 40px;
    }

    .vicles_hookMessage
    {
        font-family: 'poppins', cursive;
        font-size: 24px;
    }

    .vicles_benefits_card_text {
        font-family: "poppins", cursive;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }

    .vicles_benefits_card_title {
        font-family: "poppins", cursive;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }
}