@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Righteous&display=swap');

:root{
    --main: #FD6F00;
    --second : #4F7EF9;
    --white60: rgba(255, 255, 255, 0.6);
    --black15: rgba(0, 0, 0, 0.15);
    --black60: rgba(0, 0, 0, 0.6);
    --black: #12161F;
    --white: rgb(255, 255, 255);
    --peach: #FFE4D0;
    --offWhite: #EFF1F9;
    --gray: #E3E3E3;
    --fullRed: #FF0000;
}

*{padding: 0;margin: 0;}

body
{
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

#App
{
    display: flex;
    flex-direction: column;
    width: 92vw;
    height: 100vh;
    position: relative;
}

.vicles_land
{
    display: flex;
    flex-direction: column;
}

.vicles_header
{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
}

.vicles_trapez
{
    position: absolute;
    top: 0;
    right: -4px;
    grid-area: 1 / 3 / 5 / 6;
    height: 80vh;
    aspect-ratio: 16/9;
}

.vicles_circles
{
    width: 500px;
    grid-area: circle;
}

.vicles_circle_container
{
    position: absolute;
    grid-area: 1 / 3 / 5 / 6;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
    ". . . . ."
    ". circle circle circle ."
    ". circle circle circle ."
    ". circle circle circle ."
    ". . . . .";
    top: 0;
    right: -4px;
}

.vicles_trapcirc
{
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    z-index: -1;
    width: calc(100vw - 64px);
    height: 80vh;
    top: 0;
    right: -64px;
}

.vicles_notif
{
    height: 200px;
    aspect-ratio: initial;
    position: absolute;
    grid-area: 2 / 2 / 3 / 4;
}

.vicles_square
{
    position: absolute;
    left: 5vw;
    top: 15vh;
    z-index: -1;
}

.vicles_triangle
{
    position: absolute;
    left: 13vw;
    top: 25vh;
    z-index: -1;
}

.vicles_cursor
{
    position: absolute;
    left: calc(-4vw - 16px);
    top: 22vh;
    z-index: -1;
}


.vicles_navbar
{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 128px;
    display: grid;
    align-content: center;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.vicles_logo
{
    font-family: 'righteous', cursive;
    font-size: 56px;
    color: var(--main);
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: center;
}

.vicles_drop_langs .b_listItems
{
    background-color: var(--white);
    padding: 8px;
    border-radius: 8px;
}

.vicles_drop_langs .b_listItems span
{
    display: block;
    color: var(--black);
    cursor: pointer;
}

.vicles_drop_langs .b_dropIcon
{
    color: var(--white);
}

.vicles_languages
{
    grid-area: 1 / 8 / 2 / 9;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    font-size: 20px !important;
    font-family: 'poppins', cursive;
    color: var(--white60);
}

.ri-earth-line{
    font-size: 36px !important;
}
.vicles_globe
{
    background-color: transparent;
    border: none;
    color: var(--white60);
}

.vicles_hero
{
    height: 80vh;
    position: relative;
    left: 0;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 0.3fr 1.7fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
    ". . ."
    "HookMessage HookMessage ."
    "HookMessage HookMessage .";
    z-index: 10;
}

.vicles_position_block_hook
{
    grid-area: HookMessage;
    display: grid;
    grid-template-columns: 1.7fr;
    grid-template-rows: 1fr 1.7fr 0.3fr;
    gap: 12px 0px;
    grid-template-areas:
    "."
    "."
    ".";
}

.vicles_hook
{
    font-family: 'poppins', cursive;
    font-weight: bold;
    font-size: 40px;
}

.vicles_hookMessage
{
    font-family: 'poppins', cursive;
    font-size: 24px;
}

.vicles_join_us
{
    border-radius: 12px;
    border: none;
    outline: 2px solid var(--black15);
    font-family: 'poppins', cursive;
    font-weight: bold;
    color: var(--white);
    background-color: var(--main);
    cursor: pointer;
    font-size: 24px;
    width: fit-content;
    height: fit-content;
    padding: 16px;
}

.vicles_join_us:hover
{
    background-color: var(--white);
    color: var(--main);
}

.vicles_benefits_container
{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
}

.vicles_benefits
{
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 32px;
    position: relative;
    display: block;
    text-align: center;
    padding: 8vh 0 8vh 0;
    font-weight: 700;
}

.vicles_benefits::before {
    content: attr(black); /* Black part of the text */
    color: var(--black);
    overflow: hidden;

    width: fit-content; /* or a fixed width */
}

.vicles_benefits::after {
    content: attr(orange); /* Orange part of the text */
    color: #FD6F00;
    overflow: hidden;

    z-index: -1; /* Place it behind the black text */
    margin-left: -0.1em; /* Adjust for spacing if needed */
    mix-blend-mode: difference;
}

.vicles_benefits_dock
{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 12px 12px;
    grid-template-areas:
    ". . . .";
}

.vicles_benefits_card
{
    width: 18vw;
    background-color: var(--white);
    border: 2px solid var(--black15);
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    padding: 24px 8px 24px 8px;
    border-radius: 16px;
    box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02);
}

.vicles_benefits_card_icon, .vicles_benefits_card_icon i
{
    border: none;
    background-color: transparent;
    font-size: 48px !important;
}

.vicles_benefits_card:nth-child(odd) i
{
    color: var(--main);
}

.vicles_benefits_card:nth-child(even) i
{
    color: var(--second);
}

.vicles_benefits_card_title
{
    font-family: 'poppins', cursive;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.vicles_benefits_card_text
{
    font-family: 'poppins', cursive;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.vicles_benefits_container_bottom
{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.vicles_hero_bottom
{
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.6fr 1.4fr;
    gap: 0px 0px;
    grid-template-areas:
    "title image"
    "text image";
    padding: 32px;
}

.vicles_hero_bottom_revert
{
    width: 80vw;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.6fr 1.4fr;
    gap: 0px 0px;
    grid-template-areas:
    "image title"
    "image text";
    padding: 32px;
}

.vicles_hero_bottom_image
{
    width: 400px;
    aspect-ratio: initial;
    grid-area: image;
    display: flex;
    justify-self: end;
}

.vicles_hero_bottom_image_revert
{
    width: 400px;
    aspect-ratio: initial;
    grid-area: image;
    display: flex;
    justify-self: start;
}

.vicles_hero_bottom_title
{
    grid-area: title;
    font-family: 'poppins', cursive;
    font-weight: bold;
    font-size: 40px;
}

.vicles_hero_bottom_text
{
    grid-area: text;
    font-family: 'poppins', cursive;
    font-size: 24px;
    display: flex;
    align-self: center;
}

.vicles_footer_cta_conatiner
{
    position: relative;
    width: 100vw;
    margin-top: 32px;
    bottom: 0;
    left: 0;
    padding: 32px 0 32px 0;
    background-color: var(--main);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.vicles_footer_cta_aligner
{
    width: 92vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.vicles_foooter_cta_hook
{
    font-family: 'poppins', cursive;
    font-weight: bold;
    font-size: 40px;
    color: var(--white);
}

.vicles_foooter_cta_join
{
    background-color: var(--white);
    color: var(--main);
    border: none;
    outline: 2px solid var(--white60);
    font-family: 'poppins', cursive;
    font-weight: bold;
    padding: 16px;
    cursor: pointer;
    font-size: 30px;
    border-radius: 16px;
    align-self: end;
}

.vicles_foooter_cta_join:hover
{
    background-color: var(--white60);
}

.vicles_register_panel
{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 30;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vicles_register_background
{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 30;
    top: 0;
    left: 0;
    background-color: var(--black60);
}

.vicles_register_window
{
    width: 50vw;
    padding: 16px;
    border-radius: 16px;
    position: relative;
    z-index: 31;
    background-color: var(--white);
}

.vicles_register_window_tools
{
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.vicles_register_title
{
    font-family: 'poppins', cursive;
    font-size: 20px;
    flex-grow: 1;
}

.vicles_register_icon
{
    border: none;
    background-color: var(--peach);
    margin-right: 8px;
    border-radius: 8px;
}

.vicles_register_icon i
{
    font-size: 32px !important;
}

.vicles_register_close
{
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.vicles_register_close i
{
    font-size: 24px !important;
}

.vicles_register_close:hover i
{
    color: var(--main);
    display: flex;
}

.vicles_register_fields
{
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 24px 0px;
    grid-template-areas:
    "."
    "."
    ".";
}

.vicles_register_field
{
    border: none;
    background-color: var(--offWhite);
    font-family: 'poppins', cursive;
    font-size: 20px;
    padding: 8px;
    border-radius: 8px;
}

.vicles_register_buttons
{
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 32px;
}

.vicles_register_cancel
{
    font-family: 'poppins', cursive;
    font-size: 20px;
    border: none;
    background-color: var(--gray);
    color: var(--black);
    padding: 8px 32px 8px 32px;
    border-radius: 8px;
    cursor: pointer;
}

.vicles_register_submit
{
    font-family: 'poppins', cursive;
    font-size: 20px;
    border: none;
    outline: 2px solid var(--black15);
    background-color: var(--main);
    color: var(--white);
    padding: 8px 32px 8px 32px;
    border-radius: 8px;
    margin-left: 16px;
    cursor: pointer;
}

.vicles_register_submit:hover
{
    background-color: var(--white);
    color: var(--main);
}